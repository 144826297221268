html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#root, #root > div {
  width: 100%;
  height: 100%;
}
h1{
  font-size: 1.2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  }